<template>
  <div>

    <div class="section">
      <div class="container">

          <div class="col-md-12 ml-auto mr-auto text-left">
<br><br>
<p class="font-weight-normal">	
<h3>Termos de Uso</h3>

<p class="font-weight-normal">atualizado em 13 de Abril de 2021

<p class="font-weight-normal">ACORDO COM O USUÁRIO

<p class="font-weight-normal">Este Termo de Uso apresenta as “Condições Gerais” aplicáveis ao uso dos serviços oferecidos por Rendk Rendimentos ME 
CNPJ 41.381.616/0001-37, doravante denominada “RendK”, incluindo os serviços para disponibilizar informações sobre rendimentos dos investimentos em ações na Bolsa de Valores B3 dentro do site www.rendk.com.br e aplicativos iOS ou Android.

<p class="font-weight-normal">Qualquer pessoa, doravante nominada “Usuário”, que pretenda utilizar os serviços oferecidos por RendK deverá aceitar este Termo de Uso, e todas as demais políticas e princípios que o regem. A UTILIZAÇÃO DO WEBSITE E SEUS SERVIÇOS PRESUME A ACEITAÇÃO DO TERMO DE USO.

<p class="font-weight-normal">O Usuário deverá ler, certificar-se de haver entendido e aceitar todas as condições estabelecidas no Termo de Uso, no ato do acesso ao Website, assim como nos demais documentos incorporados aos mesmos por referência, antes de seu pedido de serviço na Rendk.

<p class="font-weight-normal">O usuário pode entrar em contato com a RendK através  dos canais:
<p class="font-weight-normal">– Email contato@rendk.com.br
<p class="font-weight-normal">– Instagram @rendkrendimentos

<p class="font-weight-normal">O usuário concorda que, para ficar ciente de informações importantes relativas a sua conta e manter a comunicação com a empresa, o endereço de e-mail cadastrado será o canal de comunicação utilizado pelo Rendk.

<p class="font-weight-normal">1 OBJETO
<p class="font-weight-normal">Os serviços objeto do presente Termo de Uso consistem na disponibilização de informações gráficas/relatórios e dados em página web das negociações efetuadas na bolsa de valores B3 que foram importadas pelo usuário.
<p class="font-weight-normal">1.1 A Rendk não vende produtos, somente presta os serviços divulgados conforme site.
<p class="font-weight-normal">1.2 A Rendk somente disponibiliza de forma estruturada as informações já importadas pelo usuário. 
<p class="font-weight-normal">1.3 Nenhum operação de compra/venda será efetuada na bolsa de valores, direta ou indiretamente pela Rendk.
<p class="font-weight-normal">1.4 As informações disponibilizadas pela Rendk poderão ter divergências em relação a posição na B3, devido ser considerados os dados/arquivos que foram utilizados pelo usuário, ao qual podem não corresponder aos mesmos dados da Bolsa B3.
<p class="font-weight-normal">1.5 A Rendk não se responsabiliza por eventuais divergências em relação aos dados mostrados quanto a posição das ações e dados de imposto de Renda. As informações disponibilizadas são apenas amostras ou simulações que deverão ser apuradas pelo usuário conforme as notas de corretagem das corretoras e dados oficiais da Bolsa B3.
<p class="font-weight-normal">1.6  As informações contidas em nosso site só poderão ser utilizadas pelos Usuários para seu uso pessoal, sem propósitos comerciais. Os Usuários não podem copiar, distribuir ou redistribuir os dados, nem vendê-los ou revendê-los para terceiros. Os conteúdos disponibilizados possuem caráter meramente informativo, portanto não devem ser considerados como recomendação de investimento. A Rendk não se responsabiliza por perdas, danos (diretos, indiretos e incidentais), custos e lucros cessantes, sejam decorridos de informações nossas ou postadas por qualquer parceiro ou Usuário. A Rendk ou seus parceiros não serão responsabilizados juridicamente por qualquer perda ou dano, direto ou indireto, que decorram de qualquer imprecisão ou falta de complemento das informações, atrasos, interrupções, erros ou omissões no fornecimento dos dados contidos e/ou calculados neste site.
<p class="font-weight-normal">1.7 A Rendk utiliza os cookies para facilitar o uso e melhor adaptar o site aos interesses do usuário. Os cookies também podem ser utilizados para ajudar a acelerar suas atividades e experiências futuras em nossa Página. Também utilizamos cookies para compilar estatísticas anônimas e agregadas que nos permitem entender como as pessoas utilizam nossa Página e para nos ajudar a melhorar suas estruturas e conteúdos.
<p class="font-weight-normal">1.8 A forma e as funcionalidades do site e plataforma poderão mudar ocasionalmente, sem aviso prévio. Da mesma forma, o modelo de exploração de cada funcionalidade da plataforma e site podem ser alterados.
<p class="font-weight-normal">1.9 A disponibilização dos serviços prestados pela Rendk estão sujeitos aos pagamentos das assinaturas e planos vigentes

<p class="font-weight-normal">2. CANCELAMENTO 

<p class="font-weight-normal">2.1  O Cancelamento poderá ser efetuado através do próprio site para a suspensão dos planos e assintatura a qualquer momento conforme necessidade do usuário.

<p class="font-weight-normal">3. PAGAMENTO
<p class="font-weight-normal">3.1 O Usuário está ciente e concorda que a aquisição do serviço poderá ser rejeitada dependendo da análise do risco pela gestora de pagamentos e/ou critérios internos da administração da Rendk.

<p class="font-weight-normal">3.2 A Rendk não pode garantir que os serviços prestados pela empresa responsável pela gestão de pagamentos funcionarão livres de erros, interrupções, mau funcionamento, atrasos ou outras imperfeições.


<p class="font-weight-normal">4. MODIFICAÇÕES DO TERMO DE USO.
<p class="font-weight-normal">4.1 A Rendk poderá alterar, a qualquer tempo, este Termo de Uso, visando seu aprimoramento e melhoria dos serviços prestados. O novo Termo de Uso entrará em vigor a partir de sua publicação no website. No prazo de 24 (vinte e quatro) horas contadas a partir da publicação das modificações, o Usuário deverá comunicar por e-mail caso não concorde com o Termo de Uso alterado. Nesse caso, o vínculo contratual deixará de existir, desde que não haja contas ou dívidas em aberto em nome do Usuário. Não havendo manifestação no prazo estipulado, entender-se-á que o Usuário aceitou tacitamente o novo Termo de Uso e o contrato continuará vinculando as partes.

<p class="font-weight-normal">5. PRIVACIDADE DA INFORMAÇÃO

<p class="font-weight-normal">5.1 A Rendk tomará todas as medidas possíveis para manter a confidencialidade e a segurança descritas nesta cláusula, porém não responderá por prejuízo que possa ser derivado da violação dessas medidas por parte de terceiros que utilizem as redes públicas ou a internet, subvertendo os sistemas de segurança para acessar as informações de Usuários.

<p class="font-weight-normal">5.2 Todas as requisições realizadas no site da Rendk são realizadas através de protocolo de segurança SSL (HTTPS), portanto os dados são criptografados de forma segura

<p class="font-weight-normal">5.3 A Rendk não armazena nenhuma informação do cartão de crédito do cliente em seus sistemas, os dados do cartão são enviados de forma segura para a operadora de cartão de crédito diretamente 

<p class="font-weight-normal">5.4 A qualquer momento o usuário pode remover todos os dados e suas informações pela gestão do perfil.

<p class="font-weight-normal">5.5 Para obter maiores informações sobre a Política de Privacidade da Rendk, o Usuário pode acessar o link <a href="www.rendk.com.br/#/termos-de-uso">www.rendk.com.br/#/termos-de-uso</a> ou obter informações através de contato@Rendk.com.br.

<p class="font-weight-normal">6. CONDIÇÕES GERAIS

<p class="font-weight-normal">6.1 Fica eleito o foro da Comarca de Rio Claro, Estado de São Paulo, como competente para dirimir quaisquer dúvidas ou controvérsias decorrentes deste TERMO DE USO independentemente de qualquer outro, por mais privilegiado que seja ou venha a ser.


</p>	
	
	   </div>
	 </div>
	</div>
	
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
import { BIcon, BICheck2} from 'bootstrap-vue'

export default {
  name: 'landing',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
	  imgSrc: '/img/rendk-imposto3.png',
      form: {
        firstName: '',
        email: '',
        message: ''
      }
    };
  }
};
</script>
<style></style>
