<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <nav>
        <ul>
          <li>
            <a href="https:///www.rendk.com.br">
              RendK
            </a>
          </li>
          <li>
            <a href="https:///www.rendk.com.br">
              Sobre Nós
            </a>
          </li>
          <li>
            <a href="https:///www.rendk.com.br">
              Dúvidas 
            </a>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{ year }} 
        <a href="https:///www.rendk.com.br/#/termos-de-uso" target="_blank" rel="noopener"
          >Termos e políticas de privacidade.</a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
