<template>
  <navbar
    position="fixed"
    type="primary"

    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
		<img class="n-logo" src="img/rendk_logo.png" alt="" width="200" />
      </router-link>
      <el-popover
        ref="popover1"
        popper-class="popover"
        placement="bottom"
        width="200"
        trigger="hover"
      >
        <div class="popover-body">
          RendK Rendimentos
        </div>
      </el-popover>
    </template>
	
    <template slot="navbar-menu">

      <li class="nav-item">
        <a
          class="nav-link btn btn-neutral"
          href="https://portal.rendk.com.br"
          target="_blank"
        >
          <i class="now-ui-icons arrows-1_share-66"></i>
          <p>Acesse o Portal</p>
        </a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Follow us on Instagram"
          data-placement="bottom"
          href="https://www.instagram.com/rendkrendimentos"
          target="_blank"
        >
          <i class="fab fa-instagram"></i>
          <p class="d-lg-none d-xl-none">Instagram</p>
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import { Navbar } from '@/components';
import { Popover } from 'element-ui';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    Navbar,
    [Popover.name]: Popover
  }
};
</script>

<style scoped></style>
