<template>
  <div>
    <div class="page-header page-header-small clear-filter" filter-color="transparent">
      <parallax
        class="page-header-image"
        style="background-image: url('img/hand2.png')" 
      >
      </parallax>
      <div class="content-center">
        <div class="container">
		  <div class="row">
			  <h2 class="title">Visibilidade dos seus investimentos em ações</h2>
			  <div class="text-center">
				<h4 class="">
				  Acompanhe suas negociações realizadas na bolsa de valores B3 de forma simples e fácil.
				  Gráficos personalizados para entender sua rentabilidade por período, lucro das vendas e dividendos recebidos.
				</h4>
				<a
					href="http://portal.rendk.com.br"
					class="btn btn-success btn-round btn-lg"
					target="_blank"
				  >
				<i class="now-ui-icons arrows-1_share-66"></i>Teste grátis
				</a>
			 </div>
		 </div>		  
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">Quanto foi a rentabilidade ?</h2>
            <h5 class="">
              Através de gráficos sumarizados, você pode acompanhar a rentabilidade mensal e acumulada por período.
              Sua rentabilidade e lucro são calculados baseados na ações em carteira, nas vendas efetuadas e proventos recebidos.
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-5">
              <div
                class="image-container image-left"
                style="background-image: url('img/rendk-rentabilidade3.png'); background-repeat: no-repeat; background-size: 100% 100%;"
              >
                <!-- First image on the left side -->
                <p class="blockquote blockquote-primary">
                  É possível visualizar sua lucratividade mensal e também o acumulado no período.
                </p>
              </div>
              <!-- Second image on the left side of the article -->
              <div
                class="image-container"
                style="background-image: url('img/rendk-vendas1.png'); background-repeat: no-repeat; background-size: 100% 100%;;"
              ></div>
            </div>
            <div class="col-md-5">

              <!-- First image on the right side, above the article -->
              <div
                class="image-container image-right"
                style="background-image: url('img/rendk-rentabilidade1.png'); background-repeat: no-repeat; background-size: 100% 100%;"
				></div>
              <h3>
                Sumário das informações
              </h3>
              <h5>
			    Fica fácil saber quanto foi sua rentabilidade com os gráficos e com o resumo dos dados da sua carteira, vendas, taxas e o resultado totalizado do período.
				Também é possível acompanhar a evolução do seu patrimônio por carteira, vendas efetuadas e proventos recebidos.
              </h5>
			  <img align="right"  src="/img/b3logo.jpg" width="100"/>
			  <h5>Integração totalmente segura com B3 - A Bolsa do brasil</h5>	
				<br>
				<br>
				<br>
				<br>				
              <h3>
                Vendas, deu lucro !
              </h3>
              <h5>
                Veja o lucro das sua vendas e o total de compras e vendas por período.
				Você pode verificar o histórico das negociações, compras e vendas efetuadas.
				Todos os dados podem ser visualizados por tabela usando filtros e ordernações de fácil interação. 
              </h5>
            </div>			
          </div>
		  
        </div>
		
      </div>
	  
	  <div class="container">
		<div class="col-md-12 ml-auto mr-auto text-center">
			<h3 class="title">Sua declaração de imposto de renda</h3>
			<h5 class="">
			Simulador de imposto de renda para declaracao anual e dos pagamentos mensais devidos.
			</h5>
		</div>
		<div class="row">
			<div class="col-md-6">
				<img src="img/rendk-imposto1.png" alt=""  />
			</div>
			<div class="col-md-6">
				<img src="img/rendk-imposto3.png" alt=""  />
			</div>		
		</div>


		
	 </div>
    </div>

    <div class="section-story-overview">
      <div class="container">
		<h2 class="title text-center">Veja nossos planos</h2>
			<div class="row">
				<div class="col-sm-3">
					<div class="card text-center">
					  <div class="card-body">
						<h4 class="card-title">Gratuito</h4>
						<p class="card-text">Até 5 negociações mensais</p>
						<p class="card-text">Até 3 importações mensais</p>
						<p class="card-text">Grátis primerio mês</p>
						<p class="card-text">Todas as funcionalidades</p>
							<a
								href="http://portal.rendk.com.br"
								class="btn btn-primary btn-round"
								target="_blank"
							  >
							<i class="now-ui-icons arrows-1_share-66"></i>Teste GRÁTIS
							</a>
					  </div>
					</div>
				</div>

				<div class="col-sm-3">
					<div class="card text-center">
					  <div class="card-body">
						<h4 class="card-title">Padrão</h4>
						<p class="card-text">Até 5 negociações mensais</p>
						<p class="card-text">Até 10 importações mensais</p>
						<p class="card-text">R$ {{plano1Valor}}/ mês</p>
						<p class="card-text">Todas as funcionalidades</p>
							<a
								href="http://portal.rendk.com.br"
								class="btn btn-primary btn-round"
								target="_blank"
							  >
							<i class="now-ui-icons arrows-1_share-66"></i>Assinar agora
							</a>
					  </div>
					</div>
				</div>

				<div class="col-sm-3">
					<div class="card text-center">
					  <div class="card-body">
						<h4 class="card-title">Avançado</h4>
						<p class="card-text">Até 20 negociações mensais</p>
						<p class="card-text">Até 20 importações mensais</p>
						<p class="card-text">R$ {{plano2Valor}}/ mês</p>
						<p class="card-text">Todas as funcionalidades</p>
							<a
								href="http://portal.rendk.com.br"
								class="btn btn-primary btn-round"
								target="_blank"
							  >
							<i class="now-ui-icons arrows-1_share-66"></i>Assinar agora
							</a>
					  </div>
					</div>
				</div>
				
				<div class="col-sm-3">
					<div class="card text-center">
					  <div class="card-body">
						<h4 class="card-title">Pró</h4>
						<p class="card-text">Negociações mensais ilimitadas</p>
						<p class="card-text">importações mensais ilimitadas</p>
						<p class="card-text">R$ {{plano3Valor}}/ mês</p>
						<p class="card-text">Todas as funcionalidades</p>
							<a
								href="http://portal.rendk.com.br"
								class="btn btn-primary btn-round"
								target="_blank"
							  >
							<i class="now-ui-icons arrows-1_share-66"></i>Assinar agora
							</a>
					  </div>
					</div>
				</div>				


</div>



<div class="col">

<h5>Funcionalidades para todos planos</h5>
<table class="table table-sm">
  <tbody>
    <tr>
      <td><b-icon icon="check2"></b-icon> Importação manual e automática das operações</td>  
    </tr>	  
    <tr>		
      <td><b-icon icon="check2"></b-icon> Suporte para múltiplas corretoras</td>
    </tr>	  
    <tr>	 	  
      <td><b-icon icon="check2"></b-icon> Sumário e evolução da rentabilidade por período</td>
    </tr>	  
    <tr>		  
      <td><b-icon icon="check2"></b-icon> Posição da carteira atual com atualização da cotação (Atraso de 15min)</td>
    </tr>
    <tr>		  
      <td><b-icon icon="check2"></b-icon> Composição da carteira por corretora e papeis</td>
    </tr>		
    <tr>		  
      <td><b-icon icon="check2"></b-icon> Histórico das vendas e informações de lucro</td>	  
    </tr>	  
    <tr>		  
      <td><b-icon icon="check2"></b-icon> Histórico da negociações</td>	
    </tr>	  
    <tr>		  
      <td><b-icon icon="check2"></b-icon> Simulação do imposto de renda mensal e declaração Anual</td>	
    </tr>	  
    <tr>		  
      <td><b-icon icon="check2"></b-icon> Atualização automática dos proventos/dividendos</td>		
    </tr>	  
    <tr>		  
      <td><b-icon icon="check2"></b-icon> Importação e Exportação das negociações</td>			  
    </tr>
    <tr>		  
      <td><b-icon icon="check2"></b-icon> Login simplificado por email ou conta social</td>			  
    </tr>	
  </tbody>
</table>
	  </div> 
	

	  <div class="text-center">
		<a
		href="http://portal.rendk.com.br"
		target="_blank"
		>
		<h5>Ainda em dúvida, navegue sem custo usando um usuário de demonstração</h5>
		</a>
		<br>
		<a
			href="http://portal.rendk.com.br"
			class="btn btn-success btn-round btn-lg"
			target="_blank"
		  >
		<i class="now-ui-icons arrows-1_share-66"></i>Acesse o Portal e teste GRÁTIS
	    </a>
	  </div>
	  
	 </div>
    </div>
	
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
import { BIcon, BICheck2} from 'bootstrap-vue'

export default {
  name: 'landing',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      plano1Valor: '10,99',
      plano2Valor: '15,99',
      plano3Valor: '20,99',		
	  imgSrc: '/img/rendk-imposto3.png',
      form: {
        firstName: '',
        email: '',
        message: ''
      }
    };
  }
};
</script>
<style></style>
